import $ from 'jquery';
import parallax from 'jquery-parallax.js';

const defaults = {
    $element: undefined,
    selectors: {
        parallaxMirror: '.parallax-mirror',
        parallaxSlider: '.parallax-slider',
    }
};

export default class Parallax {
    constructor(properties = {}) {
        const members = $.extend(true, {}, defaults, properties);

        this.$element = members.$element;
        this.selectors = members.selectors;
        this.options = members.options;
    }

    init() {
        const imageSrc = this.$element.data('image-src');

        this.$element.parallax(this.options);

        const $mirror = $(this.selectors.parallaxSlider).filter(function()
        {
            return ($(this).attr("src") === imageSrc)
        });
        const customClass = this.options.class;

        if (customClass) {
            $mirror.closest(this.selectors.parallaxMirror).addClass(customClass);
        }
    }
}
